import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet';
import layoutStyles from '../styles/style.module.scss'
import { Link } from 'gatsby'

const ProjectsPage = () => {
    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>HP Gregorio</title>
            </Helmet>
            <Layout>
                <h1 className={layoutStyles.t1}>Contribuições</h1>
                <p className={layoutStyles.parag}>100+ contribuições desde 2007 (consultoria) / 2004 (acadêmico). <Link className={layoutStyles.link} to="/contributions">Lista completa.</Link></p> 
                <h1 className={layoutStyles.t1}>Pessoais</h1>
                <p className={layoutStyles.parag}><a href="http://guaecast.hpgregorio.net" target="_blank" rel="noopener noreferrer" className={layoutStyles.link}>Guaecast:</a> Previsão de ondas para a Baixada Santista, São Sebastião e Ubatuba/SP - Brasil.</p> 
            </Layout>
        </div>
        
    )    
}

export default ProjectsPage